<template>
  <v-card height="300" class="scroll">
    <v-treeview
      :items="assets"
      :active.sync="active"
      @update:active="navigateToIDPath"
      :load-children="fetchChildren"
      :open.sync="open"
      item-text="name"
      color="warning"
      activatable
      transition
      hoverable
    >
    </v-treeview>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExplorerTree",
  data() {
    return {
      // this.assets can be a nested array due to children property.
      assets: [],
      // this.assetsAll is not a nested array.
      // assets can be easily found in this.assetsAll using its id.
      assetsAll: [],
      active: [],
      open: [],
    };
  },
  computed: mapGetters(["CDFClient"]),
  methods: {
    navigateToIDPath(id) {
      // Navigates to the selected asset page.
      this.$router.push({ path: `/explorer/${id}` });
    },
    extractAssetData(item) {
      // Extract an asset information from CDF API return value.
      // children property is set as an empty array if the asset has any child asset.
      // otherwise, is set as null.
      const assetData = {
        name: item["name"],
        description: item["description"],
        id: item["id"],
        externalId: item["externalId"],
        createdTime: item["createdTime"],
        lastUpdatedTime: item["lastUpdatedTime"],
        children: item["aggregates"]["childCount"] ? [] : null,
      };
      return assetData;
    },
    async getRootAssets() {
      const result = await this.CDFClient.assets.list({
        filter: { root: true },
        aggregatedProperties: ["childCount"],
      });
      return result["items"];
    },
    async fetchChildren(item) {
      // Fetch children assets
      // and push them to this.assets and this.assetsAll
      const result = await this.CDFClient.assets.list({
        filter: {
          parentIds: [item["id"]],
        },
        aggregatedProperties: ["childCount"],
      });
      for (let itemData of result["items"]) {
        const assetData = this.extractAssetData(itemData);
        item.children.push(assetData);
        this.assetsAll.push(assetData);
      }
    },
  },
  mounted() {
    this.getRootAssets().then((result) => {
      for (let item of result) {
        const assetData = this.extractAssetData(item);
        this.assets.push(assetData);
        this.assetsAll.push(assetData);
      }
    });
  },
};
</script>

<style>
.scroll {
  overflow-y: scroll;
}
</style>
