<template>
  <div>
    <v-container class="ma-5">
      <v-row>
        <v-col cols="4">
          <resource-search-box></resource-search-box>
          <v-spacer></v-spacer>
          <explorer-tree></explorer-tree>
        </v-col>
        <v-col>
          <dashboard-asset></dashboard-asset>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <dashboard-timeseries></dashboard-timeseries>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ExplorerTree from "@/components/ExplorerTree.vue";
import DashboardAsset from "@/components/DashboardAsset.vue";
import DashboardTimeseries from "@/components/DashboardTimeseries.vue";
import ResourceSearchBox from "@/components/ResourceSearchBox.vue";

export default {
  name: "NewDataExplorer",
  components: {
    DashboardAsset,
    ExplorerTree,
    DashboardTimeseries,
    ResourceSearchBox,
  },
};
</script>
