<template>
  <v-card class="mb-5">
    <v-card-text>
      <v-autocomplete
        v-model="model"
        :items="entries"
        item-value="name"
        :loading="isLoading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="name"
        label="Search assets"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
        @input="selectAsset"
        style="width: 300px"
      ></v-autocomplete>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  methods: {
    selectAsset(item) {
      this.$router.push(`/explorer/${item.id}`);
    },
  },
  computed: {
    items() {
      return this.entries.map((entry) => {
        return entry.name;
      });
    },
    ...mapGetters(["CDFClient"]),
  },

  watch: {
    search() {
      // Items have already been loaded
      if (this.items.length > 0) return;
      // Items have already been requested
      if (this.isLoading) return;
      this.isLoading = true;
      this.CDFClient.assets.list({limit: 1000}).then((res) => {
        this.entries = res.items;
      });
    },
  },
};
</script>
