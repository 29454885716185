<template>
  <v-card height="325">
    <v-card-title>{{ chartSeries[0]["name"] }}</v-card-title>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="chartSeries"
      v-if="showChart"
      height="250"
    ></apexchart>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "DashboardTimeseries",
  components: {},
  data() {
    return {
      showChart: false,
      chartOptions: {
        xaxis: {
          type: "datetime",
        },
        stroke: {
          width: 3,
        },
        chart: {
          animations: {
            enabled: false,
          },
        },
        tooltip: {
          x: {
            format: "yyyy/MM/dd HH:mm:ss",
          },
        },
      },
      chartSeries: [{ name: "", data: [] }],
    };
  },
  computed: {
    ...mapGetters(["selectedTimeseriesName"]),
  },
  watch: {
    selectedTimeseriesName: async function (newTimeseiresName) {
      this.showChart = false;
      this.chartSeries[0]["name"] = newTimeseiresName;
      this.chartSeries[0]["data"] = await this.getChartDatapoints(
        newTimeseiresName
      );
      this.showChart = true;
    },
  },
  methods: {
    async fetchDatapoints(tagName) {
      // Fetch DCS data
      // which are not compatible with Apexcharts.
      const result = await axios.get(
        `https://84kvb106n8.execute-api.ap-northeast-1.amazonaws.com/api/aws/${tagName}`,
        {
          headers: {
            Authorization: `${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        }
      );
      return result;
    },
    async getChartDatapoints(tagName) {
      // Fetch DCS data and make it compatible with Apexcharts.
      const datapoints = await this.fetchDatapoints(tagName);
      const chartDatapoints = [];
      for (let point of datapoints["data"]) {
        chartDatapoints.push({
          x: point["timestamp"],
          y: Math.floor(point["value"]),
        });
      }
      return chartDatapoints;
    },
  },
};
</script>
